import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getConsultantList: any = createAsyncThunk(
  "listInfoSlice/getConsultantList",
  async function (state: any, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(`/services/consultant/list/${state?.sr?state.sr:''}`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setConsultantList(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const consultantInfo = createSlice({
  name: "consultantInfoSlice",
  initialState: {
    consultantList: {
      data: null,
      status: null,
      error: null,
    },
  },
  reducers: {
    setConsultantList: (state, action) => {
      state.consultantList = action.payload;
    },
  },
  extraReducers: {
    [getConsultantList.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getConsultantList.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getConsultantList.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setConsultantList } = consultantInfo.actions;

export default consultantInfo.reducer;

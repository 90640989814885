import { LazyExoticComponent, ComponentType, lazy } from "react";
export interface IRoute {
  // Path, like in basic prop
  path: string;
  // Exact, like in basic prop
  exact: boolean;
  role?: string;
  // Lazy Loaded component
  component?: LazyExoticComponent<ComponentType<any>>;
  // Sub routes
  routes?: IRoute[];
  // Redirect path
  redirect?: string;
  // If router is private, this is going to be true
  private?: boolean;
}
export const routes: IRoute[] = [
  {
    path: "/",
    component: lazy(() => import("../layouts/auth-page/auth-page")),
    exact: true,
    private: false,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/users`,
    component: lazy(() => import("../layouts/users-page/users-page")),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/admin/dashboard`,
    component: lazy(
      () => import("../layouts/admin-dashboard-page/admin-dashboard-page")
    ),
    exact: true,
    role: "admin",
    private: true,
  },
  {
    path: `/admin/organizations`,
    component: lazy(
      () => import("../layouts/organizations-page/organizations-page")
    ),
    exact: true,
    role: "admin",
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/dashboard`,
    component: lazy(() => import("../layouts/dashboard-page/dashboard-page")),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/bulk-create`,
    component: lazy(
      () => import("../layouts/bulk-create-page/bulk-create-page")
    ),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/sourcing-request`,
    component: lazy(
      () => import("../layouts/sourcing-request-page/sourcing-request-page")
    ),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/contracts`,
    component: lazy(() => import("../layouts/contracts-page/contracts-page")),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  // {
  //   path: `/${window.sessionStorage.getItem("role")}/`,
  //   component: lazy(() => import("../components/common/pdf-editor/pdf-editor")),
  //   exact: true,
  //   role: `${window.sessionStorage.getItem("role")}`,
  //   private: false,
  // },
  {
    path: `/${window.sessionStorage.getItem("role")}/messages`,
    component: lazy(() => import("../layouts/messages-page/messages-page")),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/messages/:id`,
    component: lazy(() => import("../layouts/messages-page/messages-page")),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/documents`,
    component: lazy(() => import("../layouts/documents-page/documents-page")),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/supplier-contracts`,
    component: lazy(
      () => import("../layouts/supplier-contracts-page/supplier-contracts-page")
    ),
    exact: true,
    role: "supplier",
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/sourcing-events`,
    component: lazy(
      () =>
        import(
          "../layouts/supplier-sourcing-events-page/supplier-sourcing-events-page"
        )
    ),
    exact: true,
    role: "supplier",
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/sourcing-events/:id/event`,
    component: lazy(
      () =>
        import(
          "../layouts/supplier-sourcing-events-page/supplier-sourcing-event-page"
        )
    ),
    exact: true,
    role: "supplier",
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/suppliers`,
    component: lazy(() => import("../layouts/suppliers-page/suppliers-page")),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/reports`,
    component: lazy(() => import("../layouts/reports-page/reports-page")),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/service`,
    component: lazy(
      () => import("../layouts/services-service-page/services-service-page")
    ),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/commodity`,
    component: lazy(
      () => import("../layouts/services-commodity-page/services-commodity-page")
    ),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem("role")}/consultant`,
    component: lazy(
      () =>
        import("../layouts/services-consultant-page/services-consultant-page")
    ),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem(
      "role"
    )}/sourcing-request/:id/sourcing-event/:id`,
    component: lazy(
      () => import("../layouts/sourcing-event-page/sourcing-event-page")
    ),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem(
      "role"
    )}/sourcing-request/:id/sourcing-event/:id/supplier-answers/:id`,
    component: lazy(
      () =>
        import(
          "../layouts/sourcing-event-supplier-answers-page/sourcing-event-supplier-answers-page"
        )
    ),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem(
      "role"
    )}/sourcing-request/:id/sourcing-event/create`,
    component: lazy(
      () =>
        import(
          "../layouts/sourcing-event-create-page/sourcing-event-create-page"
        )
    ),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: `/${window.sessionStorage.getItem(
      "role"
    )}/sourcing-request/:id/sourcing-event/:id/edit`,
    component: lazy(
      () =>
        import("../layouts/sourcing-event-edit-page/sourcing-event-edit-page")
    ),
    exact: true,
    role: `${window.sessionStorage.getItem("role")}`,
    private: true,
  },
  {
    path: "/email/verify/",
    component: lazy(() => import("../layouts/verify-page/verify-page")),
    exact: true,
    private: false,
  },
  {
    path: "/external-supplier/register/",
    component: lazy(
      () =>
        import(
          "../layouts/register-external-supplier-page/register-external-supplier-page"
        )
    ),
    exact: true,
    private: false,
  },
  {
    path: "/shared-link/",
    component: lazy(
      () => import("../layouts/shared-documents-page/shared-documents-page")
    ),
    exact: true,
    private: false,
  },
  {
    path: "/not-found",
    component: lazy(() => import("../layouts/not-found/not-found")),
    exact: true,
    private: false,
  },
  {
    path: "*",
    exact: false,
    redirect: "/not-found",
    // fallback: <h1>Loading</h1>,
  },

  // {
  //   path: "/orders",
  //   component: lazy(() => import("../pages/orders/orders")),
  //   exact: true,
  //   role: "both",
  //   private: true,
  //   fallback: <Loader text="Data Is Being Loaded" />,
  // },
  // {
  //   path: "/products",
  //   component: lazy(() => import("../pages/products/products")),
  //   exact: true,
  //   role: "admin",
  //   private: true,
  //   fallback: <Loader text="Data Is Being Loaded" />,
  // },
  // {
  //   path: "/price-list",
  //   component: lazy(() => import("../pages/price-list/price-list")),
  //   exact: true,
  //   role: "admin",
  //   private: true,
  //   fallback: <Loader text="Data Is Being Loaded" />,
  // },
  // {
  //   path: "/market-analysis",
  //   component: lazy(() => import("../pages/market-analysis/market-analysis")),
  //   exact: true,
  //   role: "admin",
  //   private: true,
  //   fallback: <Loader text="Data Is Being Loaded" />,
  // },
  // {
  //   path: "/messages",
  //   component: lazy(() => import("../pages/messages/messages")),
  //   exact: true,
  //   role: "both",
  //   private: true,
  //   fallback: <Loader text="Data Is Being Loaded" />,
  // },
  // {
  //   path: "/categories",
  //   component: lazy(() => import("../pages/categories/categories")),
  //   exact: true,
  //   private: true,
  //   role: "admin",
  //   fallback: <Loader text="Data Is Being Loaded" />,
  // },
  // {
  //   path: "/discount",
  //   component: lazy(() => import("../pages/discount/discount")),
  //   exact: true,
  //   private: true,
  //   role: "admin",
  //   fallback: <Loader text="Data Is Being Loaded" />,
  // },
];

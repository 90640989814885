import axios from "axios";

export const API_URL = "https://backend.jmb-inventory-system.com/api/v1/";
const apiLogin = axios.create({
  baseURL: API_URL,
});
apiLogin.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      Accept: "*/*",
    };
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

apiLogin.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 && window.location.pathname !== "/") {
      // Logout the user here
      logoutUser();
    }
    return Promise.reject(error);
  }
);

const logoutUser = () => {
  // Perform logout actions here, such as clearing session storage, redirecting, etc.
  sessionStorage.removeItem("role");
  sessionStorage.removeItem("access");
  sessionStorage.removeItem("refresh");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("me");
  sessionStorage.removeItem("document");
  sessionStorage.removeItem("selectedOrganization");
  window.location.replace("/");
  // Add any other necessary logout logic
  // Example: window.location.href = "/login"; // Redirect to the login page
};

export default apiLogin;

import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getOrganization: any = createAsyncThunk(
  "tableInfoSlice/getOrganization",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    try {
      let response = await api.get(`/organization/${state}`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setOrganization(response.data));
        window.sessionStorage.setItem("loading", "false");
      }
      if (!response.status) {
        window.sessionStorage.setItem("loading", "false");
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      window.sessionStorage.setItem("loading", "false");
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    organization: {
      data: {},
      status: null,
      error: null,
    },
  },
  reducers: {
    setOrganization: (state, action) => {
      state.organization.data = action.payload;
    },
  },
  extraReducers: {
    [getOrganization.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getOrganization.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getOrganization.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setOrganization } = tableInfo.actions;

export default tableInfo.reducer;

import React, { FC } from "react";
import { ButtonProps } from "./button.t";
import style from "./button.module.css";
const Button: FC<ButtonProps> = (props) => {
  return (
    <button
      disabled={props.disabled}
      className={style.button}
      style={
        props.disabled
          ? { ...props.style, backgroundColor: "grey", color: "#000" }
          : { ...props.style }
      }
      onClick={props.onClick}
      type={props.type}
    >
      {props.icon}
      <p>{props.text}</p>
    </button>
  );
};

export default Button;

import { FC, useEffect, useState } from "react";
import { Doughnut, Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { PieChartProps } from "./pie-chart.t";
import style from "./pie-chart.module.css";
const PieChart: FC<PieChartProps> = ({ dataPie }) => {
  ChartJS.register(ArcElement, Tooltip);
  const [pieState, setPieState] = useState(dataPie);
  useEffect(() => {
    setPieState(dataPie);
  }, [dataPie]);

  console.log(dataPie);

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: pieState.labels,
    borderWidth: 3,
    datasets: [
      {
        data: pieState.data,
        backgroundColor: pieState.background,
        hoverBackgroundColor: pieState.backgroundHover,
      },
    ],
  };
  const plugins = [
    {
      beforeDraw: function (chart: any) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 230).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = pieState.total,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);

        ctx.save();
      },
    },
  ];
  return (
    <div className={style.pieWrapper}>
      <div className={style.doughnutWrapper}>
        {pieState.total !== undefined && (
          // @ts-ignore
          <Doughnut data={data} plugins={plugins} />
        )}
      </div>
      <div className={style.legends}>
        {pieState.legends.map((pie: any, index: number) => (
          <div className={style.legend} key={index}>
            <div
              style={{
                background: pie.background,
              }}
            >
              {pie.count}
            </div>
            <h5>: {pie.type}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChart;

import React, { FC } from "react";
import { Bar } from "react-chartjs-2";
import { BarChartProps } from "./bar-chart.t";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ScriptableContext,
} from "chart.js";
const BarChart: FC<BarChartProps> = ({data}) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

 
  const options = {
    indexAxis: "x" as const,
    
    elements: {
      bar: {
          barWidth:10,
        borderWidth: 2,
      },
    },
    responsive: true,
  };
  return (
    <div>
        {/* @ts-ignore */}
      <Bar style={{ maxHeight: 400 }} options={options}  data={data} />
    </div>
  );
};

export default BarChart;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Input, TextArea } from "../../../../components/common";
import { AnswerTypeProps, QuestionProps, ThirdStepProps } from "./third-step.t";
import style from "./third-step.module.css";
const AnswerType = ({
  id,
  idx,
  formik,
  question,
  categoryId,
}: AnswerTypeProps) => {
  const [answerType, setAnswerType] = useState("yes_no");

  const changeQuestionText = (id: number, text: string) => {
    const questionary = { ...formik.sre_questionary.value };
    let categoriesCopy = [...formik.sre_questionary.value.categories];
    let currentCategoryIndex = categoriesCopy.findIndex(
      (elem) => elem.id === categoryId
    );
    let questions = categoriesCopy[currentCategoryIndex].questions;
    questions[id] = { ...questions[id], text: text };
    let currentCategory = {
      ...categoriesCopy[currentCategoryIndex],
      questions: [...questions],
    };
    categoriesCopy[currentCategoryIndex] = currentCategory;
    formik.sre_questionary.setFieldValue("sre_questionary", {
      ...questionary,
      categories: [...categoriesCopy],
    });
  };
  const changeQuestionWeight = (id: number, weight: string) => {
    const questionary = { ...formik.sre_questionary.value };
    let categoriesCopy = [...formik.sre_questionary.value.categories];
    let currentCategoryIndex = categoriesCopy.findIndex(
      (elem) => elem.id === categoryId
    );
    let questions = categoriesCopy[currentCategoryIndex].questions;
    questions[id] = { ...questions[id], weight: weight };
    let currentCategory = {
      ...categoriesCopy[currentCategoryIndex],
      questions: [...questions],
    };
    categoriesCopy[currentCategoryIndex] = currentCategory;
    formik.sre_questionary.setFieldValue("sre_questionary", {
      ...questionary,
      categories: [...categoriesCopy],
    });
  };
  const changeQuestionAnswer = (id: number, answer: string | undefined) => {
    const questionary = { ...formik.sre_questionary.value };
    let categoriesCopy = [...formik.sre_questionary.value.categories];
    let currentCategoryIndex = categoriesCopy.findIndex(
      (elem) => elem.id === categoryId
    );
    let questions = [...categoriesCopy[currentCategoryIndex].questions];
    questions[id] = { ...questions[id], answer: answer ? answer : "" };
    let currentCategory = {
      ...categoriesCopy[currentCategoryIndex],
      questions: [...questions],
    };
    categoriesCopy[currentCategoryIndex] = currentCategory;
    formik.sre_questionary.setFieldValue("sre_questionary", {
      ...questionary,
      categories: [...categoriesCopy],
    });
  };
  const changeQuestionYesNo = (id: number, yes_no: boolean | undefined) => {
    const questionary = { ...formik.sre_questionary.value };
    let categoriesCopy = [...formik.sre_questionary.value.categories];
    let currentCategoryIndex = categoriesCopy.findIndex(
      (elem) => elem.id === categoryId
    );
    let questions = [...categoriesCopy[currentCategoryIndex].questions];
    questions[id] = { ...questions[id], yes_no: yes_no ? yes_no : undefined };
    let currentCategory = {
      ...categoriesCopy[currentCategoryIndex],
      questions: [...questions],
    };
    categoriesCopy[currentCategoryIndex] = currentCategory;
    formik.sre_questionary.setFieldValue("sre_questionary", {
      ...questionary,
      categories: [...categoriesCopy],
    });
  };

  const removeQuestion = (id: number) => {
    const questionary = { ...formik.sre_questionary.value };
    let categoriesCopy = [...formik.sre_questionary.value.categories];
    let currentCategoryIndex = categoriesCopy.findIndex(
      (elem) => elem.id === categoryId
    );
    let filteredQuestions = categoriesCopy[
      currentCategoryIndex
    ].questions.filter((elem: any) => elem.id !== id);
    let currentCategory = {
      ...categoriesCopy[currentCategoryIndex],
      questions: [...filteredQuestions],
    };
    categoriesCopy[currentCategoryIndex] = currentCategory;
    formik.sre_questionary.setFieldValue("sre_questionary", {
      ...questionary,
      categories: [...categoriesCopy],
    });
  };
  return (
    <div key={id} className={style.topInputsWrapper}>
      <div className={style.text_action}>
        <h3>Question {id + 1}</h3>
        <CloseIcon
          onClick={() => removeQuestion(question.id)}
          sx={{
            fontSize: "18px",
            marginLeft: "auto",
            cursor: "pointer",
          }}
        />
      </div>
      <div className={style.Inputs}>
        <div className={style.inputsRow}>
          <div>
            <label htmlFor="questionText">Question Description</label>
            <TextArea
              placeholder="Question Description"
              inputType="questionText"
              inputName="text"
              formik={{
                value:
                  formik.sre_questionary.value.categories[idx].questions[id]
                    .text,
                error: Array.isArray(
                  formik.sre_questionary.error?.categories[idx]?.questions
                )
                  ? formik.sre_questionary.error?.categories[idx]?.questions[id]
                      ?.text
                  : "",
                touched: Array.isArray(
                  formik.sre_questionary.touched?.categories[idx]?.questions
                )
                  ? formik.sre_questionary.touched?.categories[idx]?.questions[
                      id
                    ]?.text
                  : true,
                handleChange: (e: any) =>
                  changeQuestionText(id, e.target.value),
              }}
            />
          </div>
        </div>
        <div className={style.bottomInputs}>
          <div>
            <label htmlFor="questionWeight">Question Weight</label>
            <Input
              placeholder="Question weight"
              inputType="questionWeight"
              inputName="number"
              min={1}
              max={100}
              formik={{
                value:
                  formik.sre_questionary.value.categories[idx].questions[id]
                    .weight,
                error: Array.isArray(
                  formik.sre_questionary.error?.categories[idx]?.questions
                )
                  ? formik.sre_questionary.error?.categories[idx]?.questions[id]
                      ?.weight
                  : "",
                touched: Array.isArray(
                  formik.sre_questionary.touched?.categories[idx]?.questions
                )
                  ? formik.sre_questionary.touched?.categories[idx]?.questions[
                      id
                    ]?.weight
                  : true,
                handleChange: (e: any) =>
                  changeQuestionWeight(id, e.target.value),
              }}
            />
          </div>
          <div>
            <label htmlFor="questionText">Answer Type</label>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes_no"
                sx={{ display: "flex", flexDirection: "row" }}
                onChange={(e: any) => {
                  changeQuestionAnswer(id, undefined);
                  changeQuestionYesNo(id, undefined);
                  setAnswerType(e.target.value);
                }}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="yes_no"
                  control={<Radio />}
                  label="Yes or No"
                />
                <FormControlLabel
                  value="answer"
                  control={<Radio />}
                  label="Answer"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <label htmlFor="questionText">Question Answer</label>
            {answerType === "yes_no" ? (
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={undefined}
                  sx={{ display: "flex", flexDirection: "row" }}
                  value={
                    formik.sre_questionary.value.categories[idx].questions[id]
                      .yes_no
                  }
                  onChange={(e: any) => changeQuestionYesNo(id, e.target.value)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            ) : (
              <TextArea
                placeholder="Question answer"
                inputType="questionAnswer"
                inputName="text"
                formik={{
                  value:
                    formik.sre_questionary.value.categories[idx].questions[id]
                      .answer,
                  error: Array.isArray(
                    formik.sre_questionary.error?.categories[idx]?.questions
                  )
                    ? formik.sre_questionary.error?.categories[idx]?.questions[
                        id
                      ]?.answer
                    : "",
                  touched: Array.isArray(
                    formik.sre_questionary.touched?.categories[idx]?.questions
                  )
                    ? formik.sre_questionary.touched?.categories[idx]
                        ?.questions[id]?.answer
                    : true,
                  handleChange: (e: any) =>
                    changeQuestionAnswer(id, e.target.value),
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const Questions: FC<QuestionProps> = ({ formik, categoryId, idx }) => {
  return (
    <div className={style.topSide}>
      <Accordion className={style.accordionWrapper}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={style.accordionView}>
            <h3>Question list</h3>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={style.addInfos}>
            <h4>Question</h4>
            <button
              onClick={() => {
                const questionary = { ...formik.sre_questionary.value };
                let categoriesCopy = [
                  ...formik.sre_questionary.value.categories,
                ];
                let currentCategoryIndex = categoriesCopy.findIndex(
                  (elem) => elem.id === categoryId
                );
                let currentCategory = {
                  ...categoriesCopy[currentCategoryIndex],
                  questions: [
                    ...categoriesCopy[currentCategoryIndex].questions,
                    {
                      id: Math.random() * 1000,
                      text: "",
                      answer: undefined,
                      yes_no: undefined,
                      weight: 0,
                    },
                  ],
                };
                categoriesCopy[currentCategoryIndex] = currentCategory;
                formik.sre_questionary.setFieldValue("sre_questionary", {
                  ...questionary,
                  categories: [...categoriesCopy],
                });
                console.log("form add");
              }}
              className={style.addForm}
              type="button"
            >
              +
            </button>
          </div>
          {formik.sre_questionary.value.categories[idx].questions.map(
            (question: any, id: number) => (
              <AnswerType
                question={question}
                id={id}
                formik={formik}
                idx={idx}
                categoryId={categoryId}
              />
            )
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
const ThirdStep: FC<ThirdStepProps> = ({ formik }) => {
  const removeCategory = (id: number) => {
    const questionary = { ...formik.sre_questionary.value };
    let categoryCopy = [...formik.sre_questionary.value.categories];
    const filteredCategory = categoryCopy.filter((elem) => elem.id !== id);
    formik.sre_questionary.setFieldValue("sre_questionary", {
      ...questionary,
      categories: [...filteredCategory],
    });
  };
  const changeCategoryTitle = (id: number, title: string) => {
    let questionary = { ...formik.sre_questionary.value };
    let categoriesCopy = [...formik.sre_questionary.value.categories];
    let currentCategoryIndex = categoriesCopy.findIndex(
      (elem) => elem.id === id
    );
    let currentCategory = {
      ...categoriesCopy[currentCategoryIndex],
      id,
      title: title,
    };
    categoriesCopy[currentCategoryIndex] = currentCategory;
    formik.sre_questionary.setFieldValue("sre_questionary", {
      ...questionary,
      categories: [...categoriesCopy],
    });
    console.log(categoriesCopy);
  };
  const changeCategoryWeight = (id: number, weight: string) => {
    let questionary = { ...formik.sre_questionary.value };
    let categoriesCopy = [...formik.sre_questionary.value.categories];
    let currentCategoryIndex = categoriesCopy.findIndex(
      (elem) => elem.id === id
    );
    let currentCategory = {
      ...categoriesCopy[currentCategoryIndex],
      id,
      weight: weight,
    };
    categoriesCopy[currentCategoryIndex] = currentCategory;
    formik.sre_questionary.setFieldValue("sre_questionary", {
      ...questionary,
      categories: [...categoriesCopy],
    });
    console.log(categoriesCopy);
  };
  return (
    <div className={style.wrapperSecondStep}>
      <div className={style.bottomSide}>
        <div className={style.addInfos}>
          <h4>Questionary category</h4>
          <button
            onClick={() => {
              const questionary = formik.sre_questionary.value;
              formik.sre_questionary.setFieldValue("sre_questionary", {
                ...questionary,
                categories: [
                  ...questionary.categories,
                  {
                    id: Math.random() * 1000,
                    title: "",
                    weight: "",
                    questions: [
                      {
                        id: Math.random() * 1000,
                        text: "",
                        answer: "",
                        yes_no: "",
                        weight: 0,
                      },
                    ],
                  },
                ],
              });
              console.log("form add");
            }}
            className={style.addForm}
            type="button"
          >
            +
          </button>
        </div>
      </div>
      {formik.sre_questionary.value.categories.map(
        (category: any, idx: number) => (
          <Accordion
            defaultExpanded
            key={idx}
            className={style.accordionWrapper}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className={style.accordionView}>
                <h3>Category form {idx + 1}</h3>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={style.category}>
                <div className={style.text_action_category}>
                  <h3>Category {idx + 1}</h3>
                  <CloseIcon
                    onClick={() => removeCategory(category.id)}
                    sx={{
                      fontSize: "18px",
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className={style.questionaryInputs}>
                  <div>
                    <label htmlFor="categoryTitle">Category Title</label>
                    <Input
                      placeholder="Category title"
                      inputType="categoryTitle"
                      inputName="text"
                      formik={{
                        value:
                          formik.sre_questionary.value.categories[idx].title,
                        error: Array.isArray(
                          formik.sre_questionary.error?.categories
                        )
                          ? formik.sre_questionary.error.categories[idx]?.title
                          : "",
                        touched: Array.isArray(
                          formik.sre_questionary.touched?.categories
                        )
                          ? formik.sre_questionary.touched.categories[idx]
                              ?.title
                          : true,
                        handleChange: (e: any) =>
                          changeCategoryTitle(category.id, e.target.value),
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="categoryWeight">Category Weight</label>
                    <Input
                      placeholder="Category weight"
                      inputType="categoryWeight"
                      min={1}
                      max={100}
                      inputName="number"
                      formik={{
                        value:
                          formik.sre_questionary.value.categories[idx].weight,
                        error: Array.isArray(
                          formik.sre_questionary.error?.categories
                        )
                          ? formik.sre_questionary.error.categories[idx]?.weight
                          : "",
                        touched: Array.isArray(
                          formik.sre_questionary.touched?.categories
                        )
                          ? formik.sre_questionary.touched.categories[idx]
                              ?.weight
                          : true,
                        handleChange: (e: any) =>
                          changeCategoryWeight(category.id, e.target.value),
                      }}
                    />
                  </div>
                </div>
                <Questions formik={formik} categoryId={category.id} idx={idx} />
              </div>
            </AccordionDetails>
          </Accordion>
        )
      )}
    </div>
  );
};

export default ThirdStep;

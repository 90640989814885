import axios from "axios";

export const API_URL = "https://backend.jmb-inventory-system.com";
const apiMedia = axios.create({
  baseURL: API_URL,
});

apiMedia.interceptors.request.use(
  function (config: any) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${window.sessionStorage.getItem("access")}`,
      "Access-Control-Allow-Origin": "*",
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiMedia;

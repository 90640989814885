import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import React, { FC, useState } from "react";
import style from "./input.module.css";
import { InputProps } from "./input.t";
const Input: FC<InputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (e: any) => {
    if (props.min && props.max) {
      if (+e.target.value > 100) {
        props.formik.handleChange({ target: { value: 100 } });
      } else {
        props.formik.handleChange(e);
      }
    } else {
      props.formik.handleChange(e);
    }
  };
  return (
    <>
      <OutlinedInput
        className={`${style.wrapper_input} ${props.className}`}
        sx={props.style}
        color="primary"
        disabled={props.disabled}
        name={props.inputType}
        id="outlined-adornment-password"
        type={
          props.inputName === "password" || props.inputName === "password2"
            ? showPassword
              ? "text"
              : "password"
            : props.inputName
        }
        value={props.formik.value}
        onChange={handleChange}
        inputProps={{
          inputProps: {
            max: props?.max,
            min: props?.min,
          },
        }}
        placeholder={props.placeholder}
        error={props.formik.touched && Boolean(props.formik.error)}
        startAdornment={
          <InputAdornment position="start">
            {props.icon && (
              <props.icon sx={{ opacity: 0.5, color: "#2a99fe" }} />
            )}
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {props.inputName === "password" ||
            props.inputName === "password2" ? (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ) : (
              <></>
            )}
          </InputAdornment>
        }
      />
      {props.formik.touched && Boolean(props.formik.error) ? (
        <span className={style.textDanger}>{props.formik.error}</span>
      ) : (
        ""
      )}
    </>
  );
};

export default Input;

import { TextareaAutosize } from "@mui/material";
import React, { FC, useState } from "react";
import style from "./textarea.module.css";
import { TextAreaProps } from "./textarea.t";
const TextArea: FC<TextAreaProps> = (props) => {
  return (
    <>
      <TextareaAutosize
        aria-label="minimum height"
        minRows={1}
        name={props.inputType}
        style={{ width: "100%" }}
        className={style.wrapper_input}
        disabled={props.disabled}
        value={props.formik.value}
        onChange={props.formik.handleChange}
        placeholder={props.placeholder}
      />
      {props.formik.touched && Boolean(props.formik.error) ? (
        <span className={style.textDanger}>{props.formik.error}</span>
      ) : (
        ""
      )}
    </>
  );
};

export default TextArea;

import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
export const getUser: any = createAsyncThunk(
  "userSlice/getUser",
  async function (state: any, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(`/users/me/`);
      console.log(response, "verify");
      if (response.status === 200 || response.status === 201) {
        dispatch(setUser(response.data));
        console.log(response.data.data.role);

        sessionStorage.setItem(
          "role",
          response.data.data.role
          //  response.data.user.role
        );
        sessionStorage.setItem(
          "me",
          JSON.stringify(response.data.data)
          //  response.data.user.role
        );
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const userInfo = createSlice({
  name: "userSlice",
  initialState: {
    userAbout: null,
    status: null,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userAbout = action.payload;
    },
  },
  extraReducers: {
    [getUser.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getUser.fulfilled]: (state: any, action: any) => {
      state.status = "fulfilled";
      state.user = action.payload;
    },
    [getUser.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});

export const { setUser } = userInfo.actions;

export default userInfo.reducer;

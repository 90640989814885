import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getSourcingRequestList: any = createAsyncThunk(
  "tableInfoSlice/getSourcingRequest",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    const role=window.sessionStorage.getItem("role");
  if(role!=='sourcing_director'&&state.isSourcingMan){
    try {
      let response:any = await api.post(
        `/sourcing/manager/?limit=20${state?.request ? "&request=" + state.request : ""}${state?.q ? "&q=" + state.q : ""}${state?.offset ? "&offset=" + state.offset : ""}${state?.category ? "&category=" + state.category : ""}${state?.currency ? "&currency=" + state.currency : ""}${state?.from_budget ? "&from_budget=" + state.from_budget : ""}${state?.to_budget ? "&to_budget=" + state.to_budget : ""}${state?.sourcing_status? "&sourcing_status=" + state.sourcing_status: ""}${state?.created_date ? "&created_date=" + state.created_date : ""}${state?.deadline_date ? "&deadline_date=" + state.deadline_date : ""}`
      ,{method:'sourcing.request.list'});
      
      if (response.status === 200 || response.status === 201) {
        dispatch(setSourcingRequestList(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
  else{
      try {
      let response:any = await api.get(
        `/sourcing/request/?limit=20${state?.request ? "&request=" + state.request : ""}${state?.q ? "&q=" + state.q : ""}${state?.offset ? "&offset=" + state.offset : ""}${state?.category ? "&category=" + state.category : ""}${state?.currency ? "&currency=" + state.currency : ""}${state?.from_budget ? "&from_budget=" + state.from_budget : ""}${state?.to_budget ? "&to_budget=" + state.to_budget : ""}${state?.sourcing_status? "&sourcing_status=" + state.sourcing_status: ""}${state?.created_date ? "&created_date=" + state.created_date : ""}${state?.deadline_date ? "&deadline_date=" + state.deadline_date : ""}`
      );
      
      if (response.status === 200 || response.status === 201) {
        dispatch(setSourcingRequestList(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }}
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    sourcingRequest: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setSourcingRequestList: (state, action) => {
      state.sourcingRequest = action.payload;
    },
  },
  extraReducers: {
    [getSourcingRequestList.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getSourcingRequestList.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getSourcingRequestList.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setSourcingRequestList } = tableInfo.actions;

export default tableInfo.reducer;

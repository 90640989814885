import { CircularProgress } from "@mui/material";
import React, { FC } from "react";
import { LoaderProps } from "./loader.t";
import style from "./loader.module.css";
const Loader: FC<LoaderProps> = ({ text }) => {
  return (
    <>
      <div className={style.blurBack}></div>
      <div className={style.loaderWrapper}>
        <CircularProgress />
        <h3>{text}</h3>
      </div>
    </>
  );
};

export default Loader;

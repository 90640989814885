import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getMessages: any = createAsyncThunk(
  "tableInfoSlice/getMessages",
  async function (state: any, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(
        `/chat/?limit=20${state?.sr? state.sr : ""}${state?.offset ? "&offset=" + state.offset : ""}${state?.search ? "&q=" + state.search : ""}${state?.method ? "&method=" + state.method : ""}`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setMessages(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    messages: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
  extraReducers: {
    [getMessages.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getMessages.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getMessages.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setMessages } = tableInfo.actions;

export default tableInfo.reducer;

import React, { FC, useEffect, useState } from "react"; 
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import style from "./sidebar.module.css";
import { useLocation, useNavigate } from "react-router-dom";

import { SidebarProps } from "./sidebar.t";
import { pagesList } from "../../../../utils/pages";
import { roles } from "../../../../utils/roles";
import { Menu, MenuItem } from "@mui/material";
const Sidebar: FC<SidebarProps> = ({ openSidebar, setOpenSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(location.pathname.split("/")[2]);
  console.log(location.pathname.split("/")[1]); 
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setActive(location.pathname.split("/")[2]);
  }
  , [location.pathname]);
  const handleChangePage = async (page: string) => {
    await navigate(`/${window.sessionStorage.getItem("role")}/${page}`);
    handleClose();
    setActive(page);
  };
  return (
    <div
      className={
        openSidebar
          ? `${style.sidebar}, ${style.active_sidebar}`
          : style.sidebar
      }
    >
      <h1>
        {
          [...roles, { title: "Admin", value: "admin" }].filter(
            (role) => role.value === window.sessionStorage.getItem("role")
          )[0].title
        }
      </h1>
      <ul className={style.navigationPages}>
        {pagesList
          .filter(
            (role) => role.role === window.sessionStorage.getItem("role")
          )[0]
          .pages.map((page, index) =>
            page.page === "services" ? (
              <>
                <li
                  key={index}
                  className={page.page === active ? style.active : ""}
                  onClick={(event: any) => handleClick(event)}
                >
                  <page.icon /> <p>{page.text}</p>
                  <ChevronRightIcon sx={{ marginLeft: "auto" }} />
                </li>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    sx={{ color: "#2b99fe" }}
                    onClick={() => {
                      handleChangePage("service");
                    }}
                  >
                    Service
                  </MenuItem>
                  <MenuItem
                    sx={{ color: "#2b99fe" }}
                    onClick={() => {
                      handleChangePage("commodity");
                    }}
                  >
                    Commodity
                  </MenuItem>
                  <MenuItem
                    sx={{ color: "#2b99fe" }}
                    onClick={() => {
                      handleChangePage("consultant");
                    }}
                  >
                    Consultant
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <li
                key={index}
                className={page.page === active ? style.active : ""}
                onClick={() => handleChangePage(page.page)}
              >
                <page.icon /> <p>{page.text}</p>
              </li>
            )
          )}
      </ul>
      <div
        className={style.controlButton}
        onClick={() => setOpenSidebar(!openSidebar)}
      >
        <ArrowBackIcon
          sx={!openSidebar ? { transform: "rotate(180deg)" } : {}}
        />
      </div>
    </div>
  );
};

export default Sidebar;

import api from "../../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getSourcingCommentsList: any = createAsyncThunk(
  "tableInfoSlice/getSourcingCommentsList",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    try {
      let response = await api.get(
        `/sourcing/comments/?${state.method?'method='+state.method:''}${state?.questionary ? "&questionary=" + state.questionary : ""}${state?.supplier ? "&supplier=" + state.supplier : ""}${state?.request ? "&request=" + state.request : ""}`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(setSourcingCommentsList(response.data));
        window.sessionStorage.setItem("loading", "false");
      }
      if (!response.status) {
        window.sessionStorage.setItem("loading", "false");
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      window.sessionStorage.setItem("loading", "false");
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    sourcingCommentsList: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setSourcingCommentsList: (state, action) => {
      state.sourcingCommentsList = action.payload;
    },
  },
  extraReducers: {
    [getSourcingCommentsList.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getSourcingCommentsList.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getSourcingCommentsList.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setSourcingCommentsList } = tableInfo.actions;

export default tableInfo.reducer;

import React, { FC } from "react";
import MultiModal from "../multi-modal/multi-modal";
import { DeleteModalProps } from "./delete-modal.t";
import style from "./delete-modal.module.css";
const DeleteModal: FC<DeleteModalProps> = ({ open, onClose, onDelete }) => {
  return (
    <MultiModal open={open} handleClose={onClose}>
      <div className={style.modalContent}>
        <h2>Confirm Deletion</h2>
        <p>Are you sure you want to delete this item?</p>
        <button className={style.cancelButton} onClick={onClose}>
          Cancel
        </button>
        <button className={style.deleteButton} onClick={onDelete}>
          Delete
        </button>
      </div>
    </MultiModal>
  );
};

export default DeleteModal;

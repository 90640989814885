import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import apiLogin from "../../utils/api-login";

//get all feedbacks
export const checkSubdomain: any = createAsyncThunk(
  "tableInfoSlice/checkSubdomain",
  async function (state: any, { rejectWithValue, dispatch }) {
    try {
      let response = await apiLogin.post(
        `/organization/check-subdomain/${state}/`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(setCheckedSubdomain(response.data));
        return response.data;
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    subdomain: {
      data: null,
      status: null,
      error: null,
    },
  },
  reducers: {
    setCheckedSubdomain: (state, action) => {
      state.subdomain = action.payload;
    },
  },
  extraReducers: {
    [checkSubdomain.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [checkSubdomain.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.subdomain = action.payload;
    },
    [checkSubdomain.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setCheckedSubdomain } = tableInfo.actions;

export default tableInfo.reducer;

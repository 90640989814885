import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getDepartmentList: any = createAsyncThunk(
  "listInfoSlice/getDepartmentList",
  async function (state: any, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(`/contracts/department/list/${state?.sr?state.sr:''}`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setDepartmentList(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const departmentInfo = createSlice({
  name: "consultantInfoSlice",
  initialState: {
    departmentList: {
      data: null,
      status: null,
      error: null,
    },
  },
  reducers: {
    setDepartmentList: (state, action) => {
      state.departmentList = action.payload;
    },
  },
  extraReducers: {
    [getDepartmentList.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getDepartmentList.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getDepartmentList.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setDepartmentList } = departmentInfo.actions;

export default departmentInfo.reducer;

import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getServicesList: any = createAsyncThunk(
  "listInfoSlice/getServicesList",
  async function (state: any, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(`/services/list/${state?.sr ? state.sr : ""}`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setServicesList(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const currencyInfo = createSlice({
  name: "consultantInfoSlice",
  initialState: {
    servicesList: {
      data: null,
      status: null,
      error: null,
    },
  },
  reducers: {
    setServicesList: (state, action) => {
      state.servicesList = action.payload;
    },
  },
  extraReducers: {
    [getServicesList.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getServicesList.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getServicesList.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setServicesList } = currencyInfo.actions;

export default currencyInfo.reducer;

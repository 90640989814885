import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getUsersList: any = createAsyncThunk(
  "tableInfoSlice/getUsersList",
  async function (state: any, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(
        `/users/list/?limit=20${state?.sr? state.sr : ""}${state?.offset ? "&offset=" + state.offset : ""}${state?.search ? "&q=" + state.search : ""}${state?.role?.length>0 ? "&role=" + state.role : ""}${state?.create_supplier ? "&create_supplier=" + state.create_supplier : ""}`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setUsersList(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    usersList: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    },
  },
  extraReducers: {
    [getUsersList.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getUsersList.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getUsersList.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setUsersList } = tableInfo.actions;

export default tableInfo.reducer;

import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PeopleIcon from "@mui/icons-material/People";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddBoxIcon from "@mui/icons-material/AddBox";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export const roles = [
  { title: "Contract Administrator", value: "contract_administrator" },
  { title: "Category Manager", value: "category_manager" },
  { title: "Lawyer", value: "lawyer" },
  { title: "Sourcing Administrator", value: "sourcing_administrator" },
  { title: "Supplier", value: "supplier" },
  { title: "Sourcing Director", value: "sourcing_director" },
  { title: "Business Lead", value: "business_lead" },
];

export const pagesList = [
  {
    role: "sourcing_director",
    pages: [
      { text: "Dashboard", icon: LeaderboardIcon, page: "dashboard" },
      { text: "Documents", icon: FolderCopyIcon, page: "documents" },
      { text: "Users", icon: PeopleIcon, page: "users" },
      {
        text: "Sourcing Request",
        icon: NoteAddIcon,
        page: "sourcing-request",
      },
      { text: "Contracts", icon: HandshakeIcon, page: "contracts" },
      { text: "Suppliers", icon: LocalShippingIcon, page: "suppliers" },
      { text: "Services", icon: LocalShippingIcon, page: "services" },
    ],
  },
  {
    role: "sourcing_administrator",
    pages: [
      { text: "Contracts", icon: HandshakeIcon, page: "contracts" },
      { text: "Documents", icon: FolderCopyIcon, page: "documents" },
      {
        text: "Sourcing Request",
        icon: NoteAddIcon,
        page: "sourcing-request",
      },
      { text: "Suppliers", icon: LocalShippingIcon, page: "suppliers" },
      { text: "Services", icon: LocalShippingIcon, page: "services" },
    ],
  },
  {
    role: "contract_administrator",
    pages: [
      { text: "Contracts", icon: HandshakeIcon, page: "contracts" },
      { text: "Documents", icon: FolderCopyIcon, page: "documents" },
      {
        text: "Sourcing Request",
        icon: NoteAddIcon,
        page: "sourcing-request",
      },
      { text: "Suppliers", icon: LocalShippingIcon, page: "suppliers" },
    ],
  },
  {
    role: "supplier",
    pages: [
      {
        text: "Related Contracts",
        icon: HandshakeIcon,
        page: "supplier-contracts",
      },
      { text: "Documents", icon: FolderCopyIcon, page: "documents" },
      { text: "Dashboard", icon: LeaderboardIcon, page: "dashboard" },
      {
        text: "Sourcing Event",
        icon: NoteAddIcon,
        page: "sourcing-events",
      },
    ],
  },
  {
    role: "lawyer",
    pages: [
      { text: "Contracts", icon: HandshakeIcon, page: "contracts" },

      { text: "Documents", icon: FolderCopyIcon, page: "documents" },
    ],
  },
  {
    role: "admin",
    pages: [
      { text: "Dashboard", icon: LeaderboardIcon, page: "dashboard" },
      { text: "Organizations", icon: ApartmentIcon, page: "organizations" },
      // { text: "Bulk Create", icon: AddBoxIcon, page: "bulk-create" },
      { text: "Reports", icon: ContentPasteIcon, page: "reports" },
    ],
  },
  {
    role: "category_manager",
    pages: [
      { text: "Contracts", icon: HandshakeIcon, page: "contracts" },
      { text: "Documents", icon: FolderCopyIcon, page: "documents" },
      {
        text: "Sourcing Request",
        icon: NoteAddIcon,
        page: "sourcing-request",
      },
      // {
      //   text: "Sourcing Event",
      //   icon: NoteAddIcon,
      //   page: "sourcing-events",
      // },
      { text: "Reports", icon: ContentPasteIcon, page: "reports" },
      { text: "Suppliers", icon: LocalShippingIcon, page: "suppliers" },
    ],
  },
];

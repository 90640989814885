import React, { FC } from "react";
import style from "./notification.module.css";
import { Menu, MenuItem } from "@mui/material";
import { NotificationProps } from "./notification.t";
import moment from "moment-timezone";
type Props = {};

const Notification: FC<NotificationProps> = ({
  anchorEl,
  open,
  handleClose,
  notificationLists,
}) => {
  const ownTime = (time: any) =>
    moment(time)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format()
      .split("T")[1]
      .split(":");
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{ height: "300px" }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {notificationLists.map((notification) => (
        <MenuItem onClick={handleClose}>
          <div className={style.messageWrapper}>
            <p className={style.message_text}>{notification.message}</p>
            <span className={style.message_time}>
              {ownTime(notification?.time)[0] +
                ":" +
                ownTime(notification?.created_time)[1]}
            </span>
          </div>
        </MenuItem>
      ))}
    </Menu>
    // <div className={styles.notificationsWrapper}>

    // </div>
  );
};

export default Notification;

import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getUsersListFolders: any = createAsyncThunk(
  "tableInfoSlice/getUsersListFolders",
  async function (state: any, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(
        `/folders/get-users/?limit=20${state?.users? "&users="+state.users : ""}${state?.offset ? "&offset=" + state.offset : ""}${state?.search ? "&q=" + state.search : ""}${state?.role?.length>0 ? "&role=" + state.role : ""}${state?.create_supplier ? "&create_supplier=" + state.create_supplier : ""}`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setUsersListFolders(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    usersListFolders: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setUsersListFolders: (state, action) => {
      state.usersListFolders = action.payload;
    },
  },
  extraReducers: {
    [getUsersListFolders.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getUsersListFolders.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getUsersListFolders.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setUsersListFolders } = tableInfo.actions;

export default tableInfo.reducer;

import React, { FC, useState } from "react";
import TextArea from "../textarea/textarea";
import { CommentFieldProps } from "./comment-field.t";
import CommentsList from "./comments-list/comments-list";
import { Collapse } from "@mui/material";
import style from "./comment-field.module.css";
import { ExpandMore, AttachFile } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "../button/button";
import api from "../../../utils/api";
import MultiModal from "../multi-modal/multi-modal";
import { getSourcingCommentsList } from "../../../redux/sourcing-request/sourcing-event/sourcing-comments";
import { useDispatch } from "react-redux";
const CommentField: FC<CommentFieldProps> = ({
  handleLeaveComment,
  currentComment,
  questionaryId,
  commentsList,
  supplierId,
  requestId,
  method,
}) => {
  const [openCommentsList, setOpenCommentsList] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const dispatch = useDispatch();
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleSendComment = async () => {
    await api.post(`sourcing/comments/?method=${method}`, {
      questionary: questionaryId ? questionaryId : undefined,
      supplier: supplierId ? supplierId : undefined,
      request: requestId ? requestId : undefined,
      text: currentComment,
    });
    dispatch(
      getSourcingCommentsList({
        method: method,
        questionary: questionaryId ? questionaryId : undefined,
        supplier: supplierId ? supplierId : undefined,
        request: requestId ? requestId : undefined,
      })
    );
    handleLeaveComment("");
  };
  const handleSendFile = async () => {
    const formData = new FormData();
    Array.from(files).forEach((file: any) => {
      formData.append("file", file);
    });
    console.log(formData.getAll("file"), "dedwedqvgetwgwe");
    // @ts-ignore
    supplierId && formData.append("supplier", supplierId);
    // @ts-ignore
    questionaryId && formData.append("questionary", questionaryId);
    // @ts-ignore
    requestId && formData.append("request", requestId);

    await api.post(`sourcing/comments/?method=${method}`, formData);
    dispatch(
      getSourcingCommentsList({
        method: method,
        questionary: questionaryId ? questionaryId : undefined,
        supplier: supplierId ? supplierId : undefined,
        request: requestId ? requestId : undefined,
      })
    );
    handleLeaveComment("");
    handleCloseModal();
  };
  const handleRemoveFile = (fileName: any) => {
    setFiles(Array.from(files).filter((file: any) => file?.name !== fileName));
  };
  console.log(files);
  return (
    <div className={style.wrapperCommentField}>
      <MultiModal
        title="Chosen Documents"
        isCloseIcon
        open={files.length > 0 ? openModal : false}
        handleClose={handleCloseModal}
      >
        <div className={style.wrapperModal}>
          <div className={style.filesList}>
            {Array.from(files)?.map((file: any) => (
              <div className={style.file}>
                <p>{file?.name}</p>
                <CancelIcon
                  sx={{ color: "#d68282", cursor: "pointer" }}
                  onClick={() => handleRemoveFile(file.name)}
                />
              </div>
            ))}
          </div>
          <div className={style.buttonsWrapper}>
            <Button
              text="Cancel"
              style={{ background: "#9f9f9f" }}
              onClick={handleCloseModal}
            />
            <Button text="Send" onClick={handleSendFile} />
          </div>
        </div>
      </MultiModal>
      <div className={style.wrapperSendPanel}>
        <div className={style.wrapperInput}>
          <>
            <label htmlFor={`file`} style={{ cursor: "pointer" }}>
              <AttachFile className={style.sendDocument} />
            </label>
            <input
              type="file"
              name="file"
              accept="application/pdf,application/vnd.ms-excel"
              id="file"
              style={{ display: "none" }}
              multiple
              onChange={(event) => {
                setFiles(event.target.files);
                console.log(event.target.files);
                handleOpenModal();
                // @ts-ignore
                // changeFile(
                //   file.id ? file.id : file.documentId,
                //   event.target.value
                // );
                // setValue(event.target.value.split("\\")[2]);
              }}
            />
          </>
          <TextArea
            inputType="comment"
            inputName="text"
            placeholder="Comment"
            formik={{
              value: currentComment,
              error: "",
              touched: false,
              handleChange: (e: any) => handleLeaveComment(e.target.value),
            }}
          />
          <ExpandMore
            sx={
              openCommentsList
                ? {
                    cursor: "pointer",
                    color: "#fff",
                    background: "#2b99fe",
                    borderRadius: "50%",
                    transform: "rotate(180deg)",
                  }
                : {
                    cursor: "pointer",
                    color: "#fff",
                    background: "#2b99fe",
                    borderRadius: "50%",
                  }
            }
            className={style.openButton}
            onClick={() => setOpenCommentsList(!openCommentsList)}
          />
        </div>
        <Button
          onClick={handleSendComment}
          text="Send"
          style={{ width: "max-content", marginLeft: "10px" }}
        />
      </div>

      <Collapse in={openCommentsList} timeout="auto" unmountOnExit>
        <CommentsList commentsList={commentsList} />
      </Collapse>
    </div>
  );
};

export default CommentField;

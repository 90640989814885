import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getSourcingRequestStatistics: any = createAsyncThunk(
  "chartInfoSlice/getSourcingRequestStatistics",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    try {
      let response = await api.get(`/sourcing/request/statistics/`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setSourcingRequestStatistics(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const sourcingRequestStatisticsInfo = createSlice({
  name: "chartInfoSlice",
  initialState: {
    sourcingRequestStatistics: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setSourcingRequestStatistics: (state, action) => {
      state.sourcingRequestStatistics = action.payload;
    },
  },
  extraReducers: {
    [getSourcingRequestStatistics.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getSourcingRequestStatistics.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getSourcingRequestStatistics.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setSourcingRequestStatistics } = sourcingRequestStatisticsInfo.actions;

export default sourcingRequestStatisticsInfo.reducer;

import api, { API_URL } from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

//get all feedbacks
export const getDocumentsOutsideSharedInvites: any = createAsyncThunk(
  "tableInfoSlice/getDocumentsOutsideSharedInvites",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    const location = window.location.href.split("shared-link/")[1].split('&')[0];
    try {
      let response = await axios.get(
        `${API_URL}folders/shared-link/${state?.token?'?token='+state.token:location}&limit=20${state?.method?'&method='+state?.method:''}${state?.offset ? '&offset='+state.offset : ""}${state?.invitor_id ?'&invitor_id='+state.invitor_id : ""}${state?.item_id?'&item_id='+state.item_id:''}${state?.invite_id?'&invite_id='+state.invite_id:''}${state?.cart_id?'&cart_id='+state.cart_id:''}${state?.q ?'&q='+state.q : ""}${state?.filter?.type ?'&type='+state?.filter?.type : ""}`,
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(setDocumentsOutsideSharedInvites(response.data));
        window.sessionStorage.setItem("loading", "false");
      }
      if (!response.status) {
        window.sessionStorage.setItem("loading", "false");
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      window.sessionStorage.setItem("loading", "false");
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    documentsOutsideSharedInvites: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setDocumentsOutsideSharedInvites: (state, action) => {
      state.documentsOutsideSharedInvites = action.payload;
    },
  },
  extraReducers: {
    [getDocumentsOutsideSharedInvites.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getDocumentsOutsideSharedInvites.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getDocumentsOutsideSharedInvites.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setDocumentsOutsideSharedInvites } = tableInfo.actions;

export default tableInfo.reducer;

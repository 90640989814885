import React, { FC, useState } from "react";
import Navbar from "./navbar/navbar";
import Sidebar from "./sidebar/sidebar";
import style from "./wrapper-page.module.css";
import { WrapperPageProps } from "./wrapper-page.t";
const WrapperPage: FC<WrapperPageProps> = (props) => {
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <div className={style.wrapperPage}>
      <Sidebar setOpenSidebar={setOpenSidebar} openSidebar={openSidebar} />
      <div className={style.rightPart}>
        <Navbar />
        <div className={style.rightPartContent}>{props.children}</div>
      </div>
    </div>
  );
};

export default WrapperPage;

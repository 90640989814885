import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../../redux/login/login";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import style from "./navbar.module.css";
import { useNavigate } from "react-router-dom";
import api from "../../../../utils/api";
import { me } from "../../../../utils/me";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { getOrganizationsList } from "../../../../redux/organizations/organizations-list";
import WS_URL from "../../../../utils/api-ws";
import Notification from "../notification/notification";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { toast } from "react-toastify";
// @ts-ignore
import NotificationSound from "../../../../assets/musics/tap-notification-180637.mp3";
const Navbar: FC = () => {
  const [selectedOrganization, setSelectedOrganization] = useState<any>(
    window.sessionStorage.getItem("selectedOrganization")
  );

  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [totalUnreadCount, setTotalUnreadCount] = useState<number>(0);
  const [newMessageNotif, setNewMessageNotif] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    window.sessionStorage.getItem("role") === "admin" &&
      dispatch(getOrganizationsList());
  }, []);
  useEffect(() => {
    setSelectedOrganization(
      window.sessionStorage.getItem("selectedOrganization")
    );
  }, [window.sessionStorage.getItem("selectedOrganization")]);
  const organizationsList = useSelector(
    (state: any) => state.organizationsList?.organizationsList?.data
  );
  const [notificationList, setNotificationList] = useState([]);
  const organizationsOption = organizationsList?.results?.map(
    (organization: any) => {
      return { value: organization.id, text: organization.name };
    }
  );
  const handleChangeOrganization = (item: any) => {
    if (item?.value) {
      setSelectedOrganization(item.value);
      window.sessionStorage.setItem("selectedOrganization", item.value);
      window.location.reload();
    }
  };
  const navigation = useNavigate();

  const handleLogout = async () => {
    await api.post("/users/logout/", {
      refresh: sessionStorage.getItem("refresh"),
    });
    dispatch(logoutUser(""));
    navigation("/");
  };

  const navigateToMessage = () => {
    navigation(`/${window.sessionStorage.getItem("role")}/messages`);
  };
  const audioPlayer = useRef<HTMLAudioElement>(null);
  const playAudio = () => {
    audioPlayer?.current?.play().catch((error) => {
      console.error("Error playing audio:", error);
    });
  };
  const webSocket = useMemo(
    () =>
      WS_URL({
        path: "personal",
        params: {},
      }),
    [isOnline]
  );
  const webSocket2 = useMemo(
    () =>
      WS_URL({
        path: "notification",
        params: {},
      }),
    []
  );
  console.log(webSocket2);

  useEffect(() => {
    if (window.sessionStorage.getItem("role") !== "admin") {
      webSocket2.onopen = function () {
        webSocket2.send(
          JSON.stringify({
            msg_type: "LIST_NOTIFICATIONS",
          })
        );
        webSocket2.onmessage = function (event: any) {
          console.log(JSON.parse(event.data as string), "keksolrgbch");
          // playAudio();
          if (JSON.parse(event.data).msg_type === "LIST_NOTIFICATIONS") {
            // audioPlayer?.current?.play();
            setNotificationList(JSON.parse(event.data as string).notifications);
          }
          if (JSON.parse(event.data).msg_type === "RECEIVE_NOTIFICATION") {
            toast.info(JSON.parse(event.data as string).message);
            if (!document.hasFocus()) {
              const sound = new Audio(NotificationSound);
              sound.play();
            }
            console.log(JSON.parse(event.data as string), "keksolrgbch");
            // setNotificationList(JSON.parse(event.data as string).data);
          }
        };
      };
      webSocket.onopen = function () {
        webSocket.send(
          JSON.stringify({
            msg_type: "WENT_ONLINE",
          })
        );
        webSocket.send(
          JSON.stringify({
            msg_type: "TOTAL_UNREAD_MESSAGE_QTY",
          })
        );
        webSocket.onmessage = function (event: any) {
          if (JSON.parse(event.data).msg_type === "TOTAL_UNREAD_MESSAGE_QTY") {
            setTotalUnreadCount(JSON.parse(event.data as string).data);
          }
        };
      };
    }
  }, []);

  if (window.sessionStorage.getItem("role") !== "admin") {
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "hidden") {
        setIsOnline(false);
        webSocket.send(
          JSON.stringify({
            msg_type: "WENT_OFFLINE",
          })
        );
      }
    });
    window.addEventListener("focus", function () {
      // The user has switched back to the website.
      setIsOnline(true);
      console.log("You are now connected to the network.");
      webSocket.send(
        JSON.stringify({
          msg_type: "WENT_ONLINE",
        })
      );
    });
  }

  console.log(totalUnreadCount);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={style.navbar}>
      <h3>{me.organization}</h3>
      <div className={style.actionWrapper}>
        <div>
          <div className={style.message}>
            <IconButton
              onClick={handleClick}
              size="small"
              // sx={{ ml: 2 }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <NotificationsIcon sx={{ color: "#fff" }} />
            </IconButton>
          </div>
          {/* {totalUnreadCount != 0 && totalUnreadCount && (
            <div className={style.totalUnread}>{totalUnreadCount}</div>
          )} */}
          <Notification
            notificationLists={notificationList}
            handleClose={handleClose}
            open={open}
            anchorEl={anchorEl}
          />
          {/* <audio
            ref={audioPlayer}
            autoPlay
            playsInline
            src={NotificationSound}
          /> */}
        </div>
        <div className={newMessageNotif ? style.parent : ""}>
          {window.sessionStorage.getItem("role") !== "admin" && (
            <div onClick={navigateToMessage} className={style.message}>
              <QuestionAnswerIcon sx={{ color: "#fff" }} />
              {totalUnreadCount != 0 && totalUnreadCount && (
                <div className={style.totalUnread}>{totalUnreadCount}</div>
              )}
            </div>
          )}
        </div>
        <div className={style.organizationSelectorNavbarWrapper}>
          {window.sessionStorage.getItem("role") === "admin" &&
            organizationsOption?.length > 0 && (
              <Autocomplete
                placeholder="Select organization"
                className={style.organizationSelectorNavbar}
                options={organizationsOption}
                value={organizationsOption.find(
                  (organization: any) =>
                    organization.value == selectedOrganization
                )}
                defaultChecked
                onChange={(event, option) => {
                  handleChangeOrganization(option);
                }}
                getOptionLabel={(option) => option.text}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select organization" />
                )}
              />
            )}
        </div>
        <div className={style.profile}>
          <p>{me.first_name + "." + me.last_name.slice(0, 1)}</p>
          <PersonIcon className={style.profileAvatar} />
        </div>
        <LogoutIcon className={style.logoutButton} onClick={handleLogout} />
      </div>
    </div>
  );
};

export default Navbar;

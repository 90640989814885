import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes as Switch } from "react-router-dom";
import { routes, IRoute } from "./config";
import RouteWithSubRoutes from "./route-with-sub-routes";

const Routes: FC = () => {
  return (
    <Switch>
      {routes.map((route: IRoute) => (
        <Route
          key={route.path || route.redirect}
          path={route.path}
          element={<RequireAuth route={route} />}
        />
      ))}
    </Switch>
  );
};

function RequireAuth({ route }: any) {
  const [user, setUser] = useState(window.sessionStorage.getItem("user"));
  const [role, setRole] = useState(window.sessionStorage.getItem("role"));
  useEffect(() => {
    setRole(window.sessionStorage.getItem("role"));
    setUser(window.sessionStorage.getItem("user"));
  }, [
    window.sessionStorage.getItem("user"),
    window.sessionStorage.getItem("role"),
  ]);
  console.log(role, route);

  return route.redirect ? (
    <Navigate to={route.redirect} />
  ) : route.private ? (
    user === "true" ? (
      // role === route.role ? (
      route.component && <route.component routes={route.routes} />
    ) : (
      // ) : (
      //   <Navigate to="/not-found" />
      // )
      <Navigate to="/" />
    )
  ) : (
    route.component && <route.component routes={route.routes} />
  );
}
export default Routes;

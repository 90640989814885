import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../utils/api-media";
import defDOcument from "../../../assets/document.pdf";
import { Modal } from "@mui/material";
export default function PdfEditor({
  handleExportPdf,
  action,
  handleClose,
  document,
}) {
  const [instanceCopy, setInstanceCopy] = useState("");

  // const documentJson = window.sessionStorage.getItem("document");
  // const documentJSON =
  //   typeof documentJson === "string" ? JSON.parse(documentJson) : {};
  const documentWithType = () => {
    if (typeof document === "string") {
      return document;
    } else {
      return URL.createObjectURL(document);
    }
  };
  console.log(document);
  const documentItem = document
    ? document
    : `${window.location.protocol}//${window.location.host}${defDOcument}`;
  const containerRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    let PSPDFKit, instance;
    (async function () {
      PSPDFKit = await import("pspdfkit");
      instance = await PSPDFKit.load({
        container,
        licenseKey: "",
        document: documentItem,
        toolbarItems: [
          ...PSPDFKit.defaultToolbarItems,
          { type: "content-editor" },
        ],
        // instantJson: document_json ? document_json : false,
        initialViewState: new PSPDFKit.ViewState({
          readOnly: action === "view",
        }),
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      });
      // document_json.format &&
      //   (await instance.applyOperations([
      //     {
      //       type: "applyInstantJson",
      //       instantJson: document_json,
      //     },
      //   ]));
      instance.setLocale("en");
      setInstanceCopy(instance);
      console.log(instance.getAnnotations(0));
      // instance.setViewState((v) =>
      //   v.set("interactionMode", PSPDFKit.InteractionMode.CONTENT_EDITOR)
      // );
    })();
    return async () => {
      setInstanceCopy(instance);
      const arrayBufferJson = await instance.exportInstantJSON();
      const arrayBuffer = await instance.exportPDF();
      const blob = new Blob([arrayBuffer], { type: "application/pdf" });

      console.log(arrayBufferJson);
      handleExportPdf(blob, arrayBufferJson);
      PSPDFKit && PSPDFKit.unload(container);
    };
  }, []);

  const handleExport = async () => {
    const arrayBufferJson = await instanceCopy.exportInstantJSON();
    const arrayBuffer = await instanceCopy.exportPDF();
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });
    const annotations = arrayBufferJson;
    console.log(annotations);
    handleExportPdf(blob, arrayBufferJson);
  };
  const handleBack = () => {
    handleClose();
  };
  console.log(documentItem);

  return (
    <>
      {action === "edit" ? (
        <button onClick={handleExport}>save</button>
      ) : (
        <button onClick={handleBack}>back</button>
      )}
      {documentItem && (
        <iframe
          title="resg"
          src={`https://pdfmaster.libertynlp.com/web/viewer.html?file=${documentItem}`}
          style={{ width: "100%", border: "0px", height: "700px" }}
        />
      )}
    </>
  );
}
// import WebViewer from "@pdftron/webviewer";
// import React, { useEffect, useRef } from "react";

// const PdfEditor = ({ document }: any) => {
//   const viewerDiv = useRef<HTMLDivElement>(null);
//   useEffect(() => {
//     WebViewer(
//       {
//         path: "lib",
//         initialDoc: document,
//       },
//       viewerDiv.current as HTMLDivElement
//     ).then((instance) => {
//       console.log(instance);
//     });
//   }, []);
//   return <div style={{ height: "100vh" }} ref={viewerDiv}></div>;
// };

// export default PdfEditor;

import React, { FC, useEffect, useState } from "react";
import Button from "../button/button";
import { FileProps } from "./file.t";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import style from "./file.module.css";
const File: FC<FileProps> = ({
  disabled,
  file,
  removeFile,
  changeFile,
  isBulk,
}) => {
  const [value, setValue] = useState(
    file.sourcingFiles
      ? file.sourcingFiles.split("/")[4]
      : file.document?.name
      ? file.document.name
      : file.document
      ? file.document.split("/")[2]
      : ""
  );
  useEffect(() => {
    setValue(
      file.sourcingFiles
        ? file.sourcingFiles.split("/")[4]
        : file.document?.name
        ? file.document.name
        : file.document
        ? file.document
        : ""
    );
  }, [file]);
  console.log(file, value);

  return (
    <div
      style={isBulk ? { marginRight: "0" } : {}}
      className={style.fileWrapper}
    >
      {removeFile && !disabled && (
        <CloseIcon
          // @ts-ignore
          onClick={() => removeFile(file.id || file.documentId)}
          sx={{ fontSize: "18px", marginLeft: "auto", cursor: "pointer" }}
        />
      )}
      <h4>{value ? value : "Choose file"}</h4>

      <InsertDriveFileIcon sx={{ fontSize: "40px", color: "#2b99fe" }} />
      <div className={style.labelButton}>
        {!disabled && (
          <label
            htmlFor={`file${file.id || file.documentId}`}
            style={{ cursor: "pointer" }}
          ></label>
        )}
        <form
          target="_blank"
          method="get"
          action={
            "https://backend.jmb-inventory-system.com" + file.sourcingFiles
          }
        >
          <Button
            text={disabled ? "Download" : "Choose"}
            type="submit"
            style={{ padding: "10px 16px" }}
          />
        </form>
      </div>
      {!disabled && (
        <input
          type="file"
          name={`file${file.id || file.documentId}`}
          id={`file${file.id || file.documentId}`}
          onChange={(event) => {
            console.log(Array.from(event.target.files!)[0]);

            changeFile(
              // @ts-ignore
              file.id ? file.id : file.documentId,
              Array.from(event.target.files!)[0]
            );
            setValue(Array.from(event.target.files!)[0].name);
          }}
        />
      )}
    </div>
  );
};

export default File;

import React, { FC, useState } from "react";
import PdfEditor from "./pdf-editor";
import { Modal } from "@mui/material";
import { PDFProps } from "./pdf.t";
// @ts-ignore
import defDOcument from "../../../assets/document (20).pdf";
import { API_URL } from "../../../utils/api-media";

const PDF: FC<PDFProps> = ({
  open,
  handleClose,
  handleExportPdf,
  document,
  action,
}) => {
  const documentWithType = () => {
    if (typeof document === "string") {
      return document;
    } else {
      return URL.createObjectURL(document);
    }
  };
  const documentItem = document ? document : defDOcument;
  console.log(documentItem);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{ width: "80%" }}>
          <PdfEditor
            handleClose={handleClose}
            action={action}
            handleExportPdf={handleExportPdf}
            document={document}
          />
          {/* {documentItem && (
            <iframe
              title="resg"
              src={`https://pdfmaster.libertynlp.com/web/viewer.html?file=${documentItem}`}
              style={{ width: "100%", border: "0px", height: "700px" }}
            />
          )} */}
        </div>
      </Modal>
    </div>
  );
};

export default PDF;

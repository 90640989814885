import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getCommodityList: any = createAsyncThunk(
  "listInfoSlice/getCommodityList",
  async function (state: any, { rejectWithValue, dispatch }) {
    try {
      let response = await api.get(`/services/commodity/list/${state?.sr?state.sr:''}`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setCommodityList(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const currencyInfo = createSlice({
  name: "commodityInfoSlice",
  initialState: {
    commodityList: {
      data: null,
      status: null,
      error: null,
    },
  },
  reducers: {
    setCommodityList: (state, action) => {
      state.commodityList = action.payload;
    },
  },
  extraReducers: {
    [getCommodityList.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getCommodityList.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getCommodityList.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setCommodityList } = currencyInfo.actions;

export default currencyInfo.reducer;

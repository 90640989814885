import React, { FC } from "react";
import { TableProps } from "./table.t";
import style from "./table.module.css";
import { Checkbox, Pagination } from "@mui/material";
const Table: FC<TableProps> = (props) => {
  const pageAmount = () => {
    if (props.countPage) {
      if (props.countPage % 20 > 0) return Math.floor(props.countPage / 20) + 1;
    }
    return;
  };
  console.log(props.checked);

  return (
    <>
      <div className={style.tableWrapper}>
        <table>
          <thead>
            <tr className={style.tableHeadRow}>
              {props.isCheckbox && props.countPage && (
                <th>
                  <Checkbox
                    checked={props.checked.length === props.countPage}
                    indeterminate={
                      props.checked.length > 0 &&
                      props.checked.length < props.countPage
                    }
                    onChange={props.handleChangeParent}
                  />
                </th>
              )}
              {props.isCollapse && <th></th>}
              <th>#</th>
              {props.theadData.map((thead) => (
                <th key={thead.id}>{thead.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>{props.tbodyData}</tbody>
        </table>
      </div>
      {!!props.countPage && (
        <Pagination
          count={pageAmount()}
          color="primary"
          page={props.page + 1}
          onChange={props.handleChangePage}
        />
      )}
    </>
  );
};

export default Table;

interface urlT {
  path: string;
  params: any;
}
const WS_URL = (url?: urlT) => {
  const params = new URLSearchParams(url?.params);
  const queryString = params.toString();
  return new WebSocket(
    `wss://backend.jmb-inventory-system.com/ws/${
      url?.path ? url?.path + "/" : ""
    }?token=${window.sessionStorage.getItem("access")}${
      queryString ? "&" + queryString : ""
    }`
  );
};
export default WS_URL;

import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getCategorySpends: any = createAsyncThunk(
  "chartInfoSlice/getCategorySpends",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    try {
      let response = await api.get(`/contracts/category/spends/`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setCategorySpends(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const categorySpendsInfo = createSlice({
  name: "chartInfoSlice",
  initialState: {
    categorySpends: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setCategorySpends: (state, action) => {
      state.categorySpends = action.payload;
    },
  },
  extraReducers: {
    [getCategorySpends.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getCategorySpends.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getCategorySpends.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setCategorySpends } = categorySpendsInfo.actions;

export default categorySpendsInfo.reducer;

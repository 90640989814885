import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import "./App.css";
import { WrapperPage } from "./components/common";
import { refreshToken } from "./redux/refresh-token/refresh-token";
import Routes from "./routes/routes";
function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  setInterval(function () {
    window.sessionStorage.getItem("user") === "true" &&
      dispatch(
        refreshToken({ refresh: window.sessionStorage.getItem("refresh") })
      );
  }, 400000);

  return (
    <div
      onMouseOver={() => window.sessionStorage.setItem("online", "true")}
      onMouseOut={() => window.sessionStorage.setItem("online", "false")}
      className="App"
    >
      {location.pathname === "/email/verify/" ||
      location.pathname === "/external-supplier/register/" ||
      location.pathname === "/shared-link/" ||
      location.pathname === "/not-found" ? (
        <Routes />
      ) : location.pathname !== "/" &&
        window.sessionStorage.getItem("user") === "true" ? (
        <WrapperPage>
          <Routes />
        </WrapperPage>
      ) : (
        <Routes />
      )}
    </div>
  );
}

export default App;

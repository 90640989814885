import api, { API_URL } from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const getVerify: any = createAsyncThunk(
  "userVerifySlice/getVerify",
  async function (state: any, { rejectWithValue, dispatch }) {
    const location = window.location.href.split("verify/")[1];
    try {
      let response = await axios.post(
        API_URL + `users/email/verify/${location}`
      );
      console.log(response, "verify");
      if (response.status === 200 || response.status === 201) {
        dispatch(setVerify(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const verify = createSlice({
  name: "userVerifySlice",
  initialState: {
    verifyToken: null,
    status: null,
    error: null,
  },
  reducers: {
    setVerify: (state, action) => {
      state.verifyToken = action.payload;
    },
  },
  extraReducers: {
    [getVerify.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getVerify.fulfilled]: (state: any, action: any) => {
      state.status = "fulfilled";
      state.verifyToken = action.payload;
    },
    [getVerify.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});

export const { setVerify } = verify.actions;

export default verify.reducer;

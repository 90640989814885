import api from "../../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getSupplierSourcingRequestEventsList: any = createAsyncThunk(
  "tableInfoSlice/getSupplierSourcingRequestEventsList",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    try {
      let response = await api.get(
        `/suppliers/events/?limit=20${state?.request ? "&request=" + state.request : ""}${state?.q ? "&q=" + state.q : ""}${state?.offset ? "&offset=" + state.offset : ""}${state?.filter?.status?.length>0 ? "&status=" + state.filter.status : ""}${state?.filter?.timeline?.length>0 ? "&timeline=" + state.filter.timeline : ""}${state?.filter?.from_deadline ? "&from_deadline=" + state.filter.from_deadline : ""}${state?.filter?.to_deadline ? "&to_deadline=" + state.filter.to_deadline : ""}`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(setSupplierSourcingRequestEventsList(response.data));
        window.sessionStorage.setItem("loading", "false");
      }
      if (!response.status) {
        window.sessionStorage.setItem("loading", "false");
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      window.sessionStorage.setItem("loading", "false");
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    supplierSourcingRequestEventsList: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setSupplierSourcingRequestEventsList: (state, action) => {
      state.supplierSourcingRequestEventsList = action.payload;
    },
  },
  extraReducers: {
    [getSupplierSourcingRequestEventsList.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getSupplierSourcingRequestEventsList.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getSupplierSourcingRequestEventsList.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setSupplierSourcingRequestEventsList } = tableInfo.actions;

export default tableInfo.reducer;

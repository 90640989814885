import { configureStore } from "@reduxjs/toolkit";
import login from "./login/login";
import refreshToken from "./refresh-token/refresh-token";
import usersList from "./users/users-list";
import usersListFolders from "./users/users-list-folders";
import totalContracts from "./total-data-chart/total-contracts";
import categorySpends from "./total-data-chart/category-spends";
import totalSuppliers from "./total-data-chart/total-suppliers";
import sourcingRequest from "./total-data-chart/sourcing-request";
import getVerify from "./verify/verify";
import getUser from "./user/user";
import sourcingRequestList from "./sourcing-request/sourcing-request-list";
import sourcingRequestEventList from "./sourcing-request/sourcing-event/sourcing-event-list";
import filterData from "./filter-data/filter-data";
import sourcingRequestCategory from "./sourcing-request/request-category";
import currencyList from "./contracts/currency-list";
import departmentList from "./contracts/department-list";
import servicesList from "./services/services-list";
import commodityList from "./services/commodity-list";
import consultantList from "./services/consultant-list";
import suppliersList from "./suppliers/suppliers-list";
import suppliersPageList from "./suppliers/suppliers-page-list";
import supplierPage from "./suppliers/supplier-page";
import contractsList from "./contracts/contracts-list";
import contractsMasterAgreementList from "./contracts/contracts-master-agreement-list";
import supplierContractsList from "./contracts/supplier-contracts-list";
import contract from "./contracts/contract";
import contractsCategory from "./contracts/contracts-category";
import supplierSourcingRequestEvent from "./suppliers/supplier-sourcing-events/supplier-sourcing-event";
import supplierSourcingRequestEventsList from "./suppliers/supplier-sourcing-events/supplier-sourcing-events-list";
import documentsList from "./documents/documents-list";
import documentsListShare from "./documents/documents-list-share";
import documentsInsideInvites from "./documents/documents-inside-invites";
import documentsOutsideInvites from "./documents/documents-outside-invites";
import documentsOutsideSharedInvites from "./documents/documents-outside-shared-invites";
import document from "./documents/document";
import messages from "./messages/messages";
import organizationsList from "./organizations/organizations-list";
import organizationTasksList from "./organizations/organization-tasks-list";
import organization from "./organizations/organization";
import contractsListForReport from "./contracts/contracts-list-for-report";
import sourcingCommentsList from "./sourcing-request/sourcing-event/sourcing-comments";
import supplierSourcingEventAnswers from "./suppliers/supplier-sourcing-events/get-supplier-sourcing-event-answer";
import contractsStatistics from "./admin-dashboard/contracts-statistics";
import suppliersStatistics from "./admin-dashboard/suppliers-statistics";
import sourcingRequestsStatistics from "./admin-dashboard/sourcing-requests-statistics";
import reportsList from "./reports/reports";
import sourcingRequestEventDocuments from "./sourcing-request/sourcing-event/sourcing-event-documents-list";
import checkSubdomain from "./check-subdomain/check-subdomain";
export const store = configureStore({
  reducer: {
    login,
    refreshToken,
    usersList,
    usersListFolders,
    totalContracts,
    totalSuppliers,
    sourcingRequest,
    categorySpends,
    getVerify,
    getUser,
    sourcingRequestList,
    sourcingRequestEventList,
    sourcingRequestEventDocuments,
    filterData,
    sourcingRequestCategory,
    currencyList,
    departmentList,
    servicesList,
    commodityList,
    consultantList,
    suppliersList,
    suppliersPageList,
    contractsList,
    supplierContractsList,
    contract,
    supplierPage,
    contractsCategory,
    supplierSourcingRequestEvent,
    supplierSourcingEventAnswers,
    supplierSourcingRequestEventsList,
    documentsList,
    organizationsList,
    organizationTasksList,
    organization,
    contractsListForReport,
    documentsListShare,
    document,
    contractsMasterAgreementList,
    documentsInsideInvites,
    documentsOutsideInvites,
    documentsOutsideSharedInvites,
    messages,
    sourcingCommentsList,
    contractsStatistics,
    suppliersStatistics,
    sourcingRequestsStatistics,
    reportsList,
    checkSubdomain,
  },
});

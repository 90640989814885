import { Autocomplete, TextField } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { File, Input, TextArea } from "../../../../components/common";
import { FirstStepProps } from "./first-step.t";
import style from "./first-step.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getSuppliersList } from "../../../../redux/suppliers/suppliers-list";
import api from "../../../../utils/api";
const FirstStep: FC<FirstStepProps> = ({ formik }) => {
  const listElem = useRef();
  const dispatch = useDispatch();
  const suppliers = useSelector(
    (state: any) => state.suppliersList.suppliersList
  );
  const [position, setPosition] = useState(0);
  useEffect(() => {
    if (position && listElem.current) {
      // @ts-ignore
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
    }
  }, [suppliers]);

  const eventTypes = [
    { text: "RFP", value: "rfp" },
    { text: "RFI", value: "rfi" },
    { text: "RFQ", value: "rfq" },
  ];
  const handleChangeFile = (id: number, file: string) => {
    let documentsCopy = [...formik.documents.value];
    let currentFileIndex = documentsCopy.findIndex(
      (elem) => (elem.id || elem.documentId) === id
    );
    let currentFile = {
      // ...documentsCopy[currentFileIndex],
      [documentsCopy[currentFileIndex].documentId ? "documentId" : "id"]: id,
      document: file,
    };
    console.log(id, currentFile);
    documentsCopy[currentFileIndex] = currentFile;
    formik.documents.setFieldValue("documents", documentsCopy);
  };
  const removeFile = (id: number) => {
    const documents = [...formik.documents.value];
    const filteredDocuments = documents.filter(
      (elem: any) => (elem.id || elem.documentId) !== id
    );
    api.delete(
      `sourcing/request/event/attach/documents/${
        window.location.pathname.split("/")[5]
      }/?document_id=${id}`
    );
    console.log(filteredDocuments, id);
    formik.documents.setFieldValue("documents", filteredDocuments);
  };
  return (
    <div className={style.firstStep}>
      <div className={style.inputs_wrapper}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="eventTitle">Event Title</label>
          <Input
            placeholder="Event title"
            inputType="eventTitle"
            inputName="text"
            formik={formik.eventTitle}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="eventText">Event Description</label>
          <TextArea
            placeholder="Event Description"
            inputType="eventText"
            inputName="text"
            formik={formik.eventText}
          />
        </div>
      </div>
      <div className={style.inputs_wrapper}>
        <div>
          <label htmlFor="eventType">Event Type</label>
          <Autocomplete
            limitTags={2}
            id="multiple-limit-tags"
            sx={{ margin: "10px 0", width: "200px" }}
            options={eventTypes}
            value={formik.eventType.value}
            className={style.filterSelector}
            filterSelectedOptions
            getOptionLabel={(option: any) => option.text}
            onChange={(event, option) => {
              formik.eventType.setFieldValue("eventType", option);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="eventType"
                placeholder="Event Type"
                sx={{ height: "57px" }}
                size="small"
              />
            )}
          />
          {formik.eventType.touched && Boolean(formik.eventType.error) && (
            <span className={style.textDanger}>{formik.eventType.error}</span>
          )}
        </div>
        <div>
          <label htmlFor="suppliers">Suppliers</label>
          <Autocomplete
            limitTags={2}
            multiple
            id="multiple-limit-tags"
            sx={{ margin: "10px 0", width: "200px" }}
            options={suppliers.data}
            value={formik.suppliers.value}
            className={style.filterSelector}
            ListboxProps={{
              // @ts-ignore
              ref: listElem,
              onScroll: async (event) => {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight &&
                  suppliers.next
                ) {
                  setPosition(listboxNode.scrollTop + listboxNode.clientHeight);
                  await dispatch(
                    getSuppliersList({
                      sl: suppliers.next.split("limit=20")[1],
                    })
                  );
                }
              },
            }}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: any) => option.name}
            onChange={(event, option) => {
              formik.suppliers.setFieldValue("suppliers", option);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="suppliers"
                placeholder="Suppliers"
                sx={{ height: "57px" }}
                size="small"
              />
            )}
          />
          {formik.suppliers.touched && Boolean(formik.suppliers.error) && (
            <span className={style.textDanger}>{formik.suppliers.error}</span>
          )}
        </div>
      </div>
      <div className={style.externalSupplierInput}>
        <label htmlFor="external_suppliers">External Suppliers</label>
        <Autocomplete
          limitTags={2}
          multiple
          id="multiple-limit-tags"
          sx={{ margin: "10px 0", width: "100%" }}
          options={[]}
          freeSolo
          value={formik.external_suppliers.value}
          className={style.filterSelector}
          // ListboxProps={{
          //   // @ts-ignore
          //   ref: listElem,
          //   onScroll: async (event) => {
          //     const listboxNode = event.currentTarget;
          //     if (
          //       listboxNode.scrollTop + listboxNode.clientHeight ===
          //         listboxNode.scrollHeight &&
          //         external_suppliers.next
          //     ) {
          //       setPosition(listboxNode.scrollTop + listboxNode.clientHeight);
          //       await dispatch(
          //         getSuppliersList({
          //           sl: external_suppliers.next.split("limit=20")[1],
          //         })
          //       );
          //     }
          //   },
          // }}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option: any) => option}
          onChange={(event, option) => {
            console.log(event, option);

            formik.external_suppliers.setFieldValue(
              "external_suppliers",
              option
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="external_suppliers"
              placeholder="External suppliers"
              sx={{ height: "57px" }}
              size="small"
            />
          )}
        />
        {formik.external_suppliers.touched &&
          Boolean(formik.external_suppliers.error) && (
            <span className={style.textDanger}>
              {formik.external_suppliers.error}
            </span>
          )}
      </div>
      <div className={style.filesWrapper}>
        <h2>Documents</h2>
        <div className={style.fileInputWrapper}>
          <div className={style.files}>
            {formik.documents.value.map((file: any) => {
              return (
                <File
                  key={file.id || file.documentId}
                  disabled={false}
                  file={file}
                  changeFile={(id, file) => handleChangeFile(id, file)}
                  removeFile={removeFile}
                />
              );
            })}
          </div>
          <button
            onClick={() => {
              const documents = [...formik.documents.value];
              formik.documents.setFieldValue("documents", [
                ...documents,
                { documentId: Math.random() * 1000, document: "" },
              ]);
            }}
            className={style.addFile}
            type="button"
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstStep;

import React, { FC } from "react";
import style from "./selector.module.css";
import { SelectorProps } from "./selector.t";
const Selector: FC<SelectorProps> = ({
  formik,
  placeholder,
  disabled,
  className,
  name,
  styleSelector,
  options,
}) => {
  return (
    <div className={`${className} ${style.wrapperSelector}`}>
      <select
        onChange={formik.handleChange}
        value={formik.value}
        name={name}
        disabled={disabled}
        style={styleSelector}
        id={name}
      >
        <option disabled value="">
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
      {formik.touched && Boolean(formik.error) ? (
        <span className={style.textDanger}>{formik.error}</span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Selector;

import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getTotalSuppliersStatistics: any = createAsyncThunk(
  "chartInfoSlice/getTotalSuppliersStatistics",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    try {
      let response = await api.get(`/suppliers/statistics/`);
      if (response.status === 200 || response.status === 201) {
        dispatch(setTotalSuppliersStatistics(response.data));
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const totalSuppliersStatisticsInfo = createSlice({
  name: "chartInfoSlice",
  initialState: {
    totalSuppliersStatistics: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setTotalSuppliersStatistics: (state, action) => {
      state.totalSuppliersStatistics = action.payload;
    },
  },
  extraReducers: {
    [getTotalSuppliersStatistics.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getTotalSuppliersStatistics.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getTotalSuppliersStatistics.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setTotalSuppliersStatistics } = totalSuppliersStatisticsInfo.actions;

export default totalSuppliersStatisticsInfo.reducer;

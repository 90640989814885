import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//login
interface RefreshTokenProps {
  refresh: string;
}

export const refreshToken: any = createAsyncThunk(
  "userSlice/refreshToken",
  async function (userData: RefreshTokenProps, { rejectWithValue, dispatch }) {
    try {
      await api
        .post(`/users/token/refresh/`, {
          refresh: userData.refresh,
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            sessionStorage.setItem("access", response.data.access);
            return { ...response.data?.user_info };
          }
          if (!response.status) {
            throw new Error("Internal Server Error");
          }
        })
        .catch((err) => {});
    } catch (error: any) {
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const userRefreshToken = createSlice({
  name: "userSlice",
  initialState: {
    access: sessionStorage.getItem("access"),
  },
  reducers: {
    setRefreshToken: (state, action) => {
      state.access = action.payload.refresh;
      sessionStorage.setItem("access", action.payload.access);
    },
  },
  extraReducers: {
    [refreshToken.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [refreshToken.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [refreshToken.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});

export const { setRefreshToken } = userRefreshToken.actions;

export default userRefreshToken.reducer;

import api from "../../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getSupplierSourcingRequestEvent: any = createAsyncThunk(
  "tableInfoSlice/getSourcingRequestEvent",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    try {
      let response = await api.get(
        `/sourcing/request/event/by-params/?${state?.sourcing_event? "sourcing-event=" + state?.sourcing_event: ""}${state?.event ? "event=" + state.event : ""}${state?.get_data ? "&get_data=" + state.get_data : ""}`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(setSupplierSourcingRequestEvent(response.data));
        window.sessionStorage.setItem("loading", "false");
      }
      if (!response.status) {
        window.sessionStorage.setItem("loading", "false");
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      window.sessionStorage.setItem("loading", "false");
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    supplierSourcingRequestEvent: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setSupplierSourcingRequestEvent: (state, action) => {
      state.supplierSourcingRequestEvent = action.payload;
    },
  },
  extraReducers: {
    [getSupplierSourcingRequestEvent.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getSupplierSourcingRequestEvent.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getSupplierSourcingRequestEvent.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setSupplierSourcingRequestEvent } = tableInfo.actions;

export default tableInfo.reducer;

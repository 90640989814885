import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUser } from "../user/user";
import { useSelector } from "react-redux";
import apiLogin from "../../utils/api-login";

//login
interface PostLoginProps {
  email: string;
  password: string;
}
// export const HandleRole = () => {
//   const data = useSelector((state: any) => state.getUser.userAbout.data);
//   return data;
// };

export const postLogin: any = createAsyncThunk(
  "userSlice/postLogin",
  async function (userData: PostLoginProps, { rejectWithValue, dispatch }) {
    try {
      let response = await apiLogin.post(`/users/token/`, {
        email: userData.email,
        password: userData.password,
      });
      if (response.status === 200 || response.status === 201) {
        sessionStorage.setItem("access", response.data.access);
        sessionStorage.setItem("refresh", response.data.refresh);
        sessionStorage.setItem("user", "true");
        window.sessionStorage.setItem(
          "document",
          JSON.stringify({
            action: "",
            document: "",
          })
        );
        // sessionStorage.setItem(
        //   "role",
        //   // "sourcing_director"
        //    response.data.role
        // );
        return { ...response.data };
      }
      if (!response.status) {
        throw new Error("Internal Server Error");
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const users = createSlice({
  name: "userSlice",
  initialState: {
    user_info: null,
    role: window.sessionStorage.getItem("role") ?? null,
    status: null,
    error: null,
  },
  reducers: {
    logoutUser: (state, action) => {
      state.status = null;
      state.role = null;
      state.user_info = null;
      state.error = null;
      sessionStorage.removeItem("role");
      sessionStorage.removeItem("access");
      sessionStorage.removeItem("refresh");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("me");
      sessionStorage.removeItem("document");
      sessionStorage.removeItem("selectedOrganization");
    },
  },
  extraReducers: {
    [postLogin.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [postLogin.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [postLogin.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});

export const { logoutUser } = users.actions;

export default users.reducer;

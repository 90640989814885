import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getDocumentsList: any = createAsyncThunk(
  "tableInfoSlice/getDocumentsList",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    try {
      let response = await api.get(
        state.type==='my'?`/folders/?limit=20${state?.offset ? '&offset='+state.offset : ""}${state?.parent ?'&parent='+state.parent : ""}${state?.q ?'&q='+state.q : ""}${state?.filter?.type ?'&type='+state?.filter?.type : ""}`:state.type==='general'?`/folders/users/?limit=20${state?.offset ? state.offset : ""}${state?.parent ?'&parent='+state.parent : ""}${state?.user ?'&user='+state.user : ""}${state?.q ?'&q='+state.q : ""}${state?.filter?.type ?'&type='+state?.filter?.type : ""}`:'',
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(setDocumentsList(response.data));
        window.sessionStorage.setItem("loading", "false");
      }
      if (!response.status) {
        window.sessionStorage.setItem("loading", "false");
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      window.sessionStorage.setItem("loading", "false");
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    documentsList: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setDocumentsList: (state, action) => {
      state.documentsList = action.payload;
    },
  },
  extraReducers: {
    [getDocumentsList.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getDocumentsList.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getDocumentsList.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setDocumentsList } = tableInfo.actions;

export default tableInfo.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const getFilterData = createSlice({
  name: "filterSlice",
  initialState: {
    filterData: {},
  },
  reducers: {
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
});
export const { setFilterData } = getFilterData.actions;

export default getFilterData.reducer;

import api from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//get all feedbacks
export const getOrganizationTasksList: any = createAsyncThunk(
  "tableInfoSlice/getOrganizationTasksList",
  async function (state: any, { rejectWithValue, dispatch }) {
    console.log(state);
    try {
      let response = await api.get(
        `/organization/task/${state?.organization_id? "?organization_id=" + state.organization_id: ""}${state?.q ? "&q=" + state.q : ""}${state?.filter?.status ? "&status=" + state?.filter?.status : ""}${state?.filter?.contract_structure ? "&contract_structure=" + state?.filter?.contract_structure : ""}${state?.filter?.category ? "&category=" + state?.filter?.category : ""}${state?.filter?.currency ? "&currency=" + state?.filter?.currency : ""}${state?.filter?.terms ? "&terms=" + state?.filter?.terms : ""}`
        );
      if (response.status === 200 || response.status === 201) {
        dispatch(setOrganizationTasksList(response.data));
        window.sessionStorage.setItem("loading", "false");
      }
      if (!response.status) {
        window.sessionStorage.setItem("loading", "false");
        throw new Error("Internal Server Error");
      }
      return;
    } catch (error: any) {
      window.sessionStorage.setItem("loading", "false");
      toast.error(error.message);
      return rejectWithValue(error.response.data.error || error.message);
    }
  }
);

export const tableInfo = createSlice({
  name: "tableInfoSlice",
  initialState: {
    organizationTasksList: {
      data: [],
      status: null,
      error: null,
    },
  },
  reducers: {
    setOrganizationTasksList: (state, action) => {
      state.organizationTasksList.data = action.payload;
    },
  },
  extraReducers: {
    [getOrganizationTasksList.pending]: (state: any) => {
      state.status = "pending";
      state.error = null;
    },
    [getOrganizationTasksList.fulfilled]: (state: any, action: any) => {
      state.status = "resolved";
      state.user_info = action.payload;
      state.role = " ";
    },
    [getOrganizationTasksList.rejected]: (state: any, action: any) => {
      state.status = "rejected";
      state.error = action.payload;
    },
  },
});
export const { setOrganizationTasksList } = tableInfo.actions;

export default tableInfo.reducer;

import React, { FC } from "react";
import Button from "../../button/button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { commentsListProps } from "./comments-list.t";
import style from "./comments-list.module.css";
const CommentsList: FC<commentsListProps> = ({ commentsList }) => {
  console.log(commentsList);

  return (
    <div className={style.wrapperComments}>
      {commentsList?.data?.map((comment: any) => (
        <div key={comment.id} className={style.commentWrapper}>
          <div className={style.comment}>
            {comment.documents.length ? (
              comment.documents.map((doc: any) => {
                console.log(doc.uploaded_file.split("/media/"));

                return (
                  <form
                    target="_blank"
                    method="get"
                    action={
                      "http://backend.jmb-inventory-system.com:1080/" +
                      doc.uploaded_file.split("/media/")[1]
                    }
                  >
                    {/* <p className={style.downloadDocument}>
                    {
                      doc.uploaded_file.split("/")[
                        doc.uploaded_file.split("/").length - 1
                      ]
                    }
                  </p> */}
                    <Button
                      icon={
                        <DownloadForOfflineIcon sx={{ fontSize: "23px" }} />
                      }
                      text={
                        doc.uploaded_file.split("/")[
                          doc.uploaded_file.split("/").length - 1
                        ]
                      }
                      type="submit"
                      style={{ padding: "10px 16px" }}
                    />
                  </form>
                );
              })
            ) : (
              <h3>{comment.text}</h3>
            )}
            <span>{comment.created_date}</span>
          </div>
          <p>
            <b>from:</b>{" "}
            {comment.author
              ? comment.author.first_name + " " + comment.author.last_name
              : comment.supplier.name}
          </p>
        </div>
      ))}
    </div>
  );
};

export default CommentsList;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { FC, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Input, TextArea } from "../../../../components/common";
import { SecondStepProps } from "./second-step.t";
import style from "./second-step.module.css";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const SecondStep: FC<SecondStepProps> = ({ formik }) => {
  const removeFile = (id: number) => {
    const infos = [...formik.sre_infos.value];
    const filteredInfos = infos.filter((elem: any) => elem.id !== id);
    console.log(filteredInfos);
    formik.sre_infos.setFieldValue("sre_infos", filteredInfos);
  };
  const changeInfosTitle = (id: number, title: string) => {
    let infosCopy = [...formik.sre_infos.value];
    let currentFileIndex = infosCopy.findIndex((elem) => elem.id === id);
    let currentFile = { ...infosCopy[currentFileIndex], id, title: title };
    infosCopy[currentFileIndex] = currentFile;
    formik.sre_infos.setFieldValue("sre_infos", infosCopy);
    console.log(infosCopy);
  };
  const changeInfosText = (id: number, text: string) => {
    let infosCopy = [...formik.sre_infos.value];
    let currentFileIndex = infosCopy.findIndex((elem) => elem.id === id);
    let currentFile = { ...infosCopy[currentFileIndex], id, text: text };
    infosCopy[currentFileIndex] = currentFile;
    formik.sre_infos.setFieldValue("sre_infos", infosCopy);
    console.log(infosCopy);
  };

  const changeQuestionaryTitle = (title: string) => {
    let questionaryCopy = { ...formik.sre_questionary.value };
    let currentFile = { ...questionaryCopy, title: title };
    questionaryCopy = currentFile;
    formik.sre_questionary.setFieldValue("sre_questionary", questionaryCopy);
    console.log(questionaryCopy);
  };

  const changeQuestionarySuccessWeight = (success_weight: string) => {
    let questionaryCopy = { ...formik.sre_questionary.value };
    let currentFile = { ...questionaryCopy, success_weight: success_weight };
    questionaryCopy = currentFile;
    formik.sre_questionary.setFieldValue("sre_questionary", questionaryCopy);
    console.log(questionaryCopy);
  };
  const changeQuestionaryDeadlineAt = (deadline_at: string) => {
    let questionaryCopy = { ...formik.sre_questionary.value };
    let currentFile = { ...questionaryCopy, deadline_at: deadline_at };
    questionaryCopy = currentFile;
    formik.sre_questionary.setFieldValue("sre_questionary", questionaryCopy);
    console.log(questionaryCopy);
  };
  console.log(formik.sre_questionary.value?.deadline_at);

  return (
    <div className={style.wrapperSecondStep}>
      <div className={style.topSide}>
        <div className={style.addInfos}>
          <h4>Infos</h4>
          <button
            onClick={() => {
              const infos = formik.sre_infos.value;
              formik.sre_infos.setFieldValue("sre_infos", [
                ...infos,
                { id: Math.random() * 1000, title: "", text: "" },
              ]);
              console.log("form add");
            }}
            className={style.addForm}
            type="button"
          >
            +
          </button>
        </div>
        <Accordion defaultExpanded className={style.accordionWrapper}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={style.accordionView}>
              <h3>Infos list</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {formik.sre_infos.value.map((infos: any, idx: number) => (
              <div key={idx} className={style.topInputsWrapper}>
                <div className={style.text_action}>
                  <h3>Infos {idx + 1}</h3>
                  <CloseIcon
                    onClick={() => removeFile(infos.id)}
                    sx={{
                      fontSize: "18px",
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className={style.InputsInfos}>
                  <div>
                    <label htmlFor="infosTitle">Infos Title</label>
                    <Input
                      placeholder="Infos title"
                      inputType="infosTitle"
                      inputName="text"
                      formik={{
                        value: formik.sre_infos.value[idx].title,
                        error: Array.isArray(formik.sre_infos.error)
                          ? formik.sre_infos.error[idx]?.title
                          : "",
                        touched: Array.isArray(formik.sre_infos.touched)
                          ? formik.sre_infos.touched[idx]?.title
                          : true,
                        handleChange: (e: any) =>
                          changeInfosTitle(infos.id, e.target.value),
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="infosText">Infos Description</label>
                    <TextArea
                      placeholder="Infos Description"
                      inputType="infosText"
                      inputName="text"
                      formik={{
                        value: formik.sre_infos.value[idx].text,
                        error: Array.isArray(formik.sre_infos.error)
                          ? formik.sre_infos.error[idx]?.text
                          : "",
                        touched: Array.isArray(formik.sre_infos.touched)
                          ? formik.sre_infos.touched[idx]?.text
                          : true,
                        handleChange: (e: any) =>
                          changeInfosText(infos.id, e.target.value),
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={style.bottomSide}>
        <h1>Questionary</h1>
        <div className={style.Inputs}>
          <div className={style.inputsRow}>
            <div>
              <label htmlFor="questionaryTitle">Questionary Title</label>
              <Input
                placeholder="Questionary title"
                inputType="questionaryTitle"
                inputName="text"
                formik={{
                  value: formik.sre_questionary.value.title,
                  error: formik.sre_questionary.error?.title,
                  touched: formik.sre_questionary.touched?.title,
                  handleChange: (e: any) =>
                    changeQuestionaryTitle(e.target.value),
                }}
              />
            </div>
            <div className={style.wrapperPicker}>
              <label htmlFor="last_name">Deadline date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  // inputFormat="MM/DD/YYYY hh:mm"
                  className={style.datePicker}
                  value={formik.sre_questionary.value?.deadline_at}
                  onChange={(e) => changeQuestionaryDeadlineAt(e)}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {formik.sre_questionary.touched?.deadline_at &&
              Boolean(formik.sre_questionary.error?.deadline_at) ? (
                <span className={style.textDanger}>
                  {formik.sre_questionary.error?.deadline_at}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={style.inputsRow}>
            <div>
              <label htmlFor="questionWeight">Total Weight</label>
              <Input
                placeholder="Total weight"
                inputType="total_weight"
                min={1}
                disabled={true}
                max={100}
                inputName="text"
                formik={{
                  value: "100%",
                  error: "",
                  touched: "",
                  handleChange: () => {},
                }}
              />
            </div>
            <div>
              <label htmlFor="questionWeight">Success Weight</label>
              <Input
                placeholder="Success weight"
                inputType="success_weight"
                min={1}
                max={100}
                inputName="number"
                formik={{
                  value: formik.sre_questionary.value.success_weight,
                  error: formik.sre_questionary.error?.success_weight,
                  touched: formik.sre_questionary.touched?.success_weight,
                  handleChange: (e: any) =>
                    changeQuestionarySuccessWeight(e.target.value),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondStep;

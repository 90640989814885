import { Modal } from "@mui/material";
import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ModalProps } from "./multi-modal.t";
import style from "./multi-modal.module.css";
const MultiModal: FC<ModalProps> = ({
  title,
  open,
  handleClose,
  children,
  isCloseIcon,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={style.modalWrapper}>
        <div className={style.topContent}>
          <h1>{title}</h1>
          {!isCloseIcon && (
            <CloseIcon
              fontSize="large"
              sx={{ cursor: "pointer" }}
              className={style.closeIcon}
              onClick={handleClose}
            />
          )}
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default MultiModal;
